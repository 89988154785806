<template>
  <div>
    <h2>Last posts</h2>
    
    <div class="row">
      <div class="col-4">
        
      </div>
    </div>
    
  </div>
</template>

<script>
// eslint-disable
export default {
  name: "instagramFeed",
  mounted() {
    this.loadLastPosts();
  },
  data() {
    return {
      posts: [],
    };
  },
  methods: {
    loadLastPosts() {
      // fetch("https://www.instagram.com/la_casita_de_los_muebles/?__a=1")
      //   .then((response) => response.json())
      //   .then((data) => {
      //     this.posts = data.graphql.user.edge_owner_to_timeline_media.edges;
      //   }).catch((error) => {
      //     console.log(error);
      //   });
    },
  },
}
</script>